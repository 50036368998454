.app-container {
  min-height: 350px;
}

/* .invalid-feedback {
	color: "#dc3545";
	margin-top: ".25rem";
	font-size: ".875em";
    background: transparent !important;
} */
.invalid-feedback {
  margin-top: 0 !important;
  margin-bottom: 12px;
  margin-top: -6px !important;
}
.kUMlDB {
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}
.fxvrKk {
  font-size: 0.84rem !important;
}
.bordered-table {
  border-collapse: collapse;
}
.generate-btn {
  color: #fff;
  background: linear-gradient(
    to bottom right,
    var(--primary-color) 0,
    var(--primary06) 100%
  );
  /*border-start-start-radius: 0;*/
  /*border-start-end-radius: 60px;*/
  /*border-end-end-radius: 60px;*/
  /*border-end-start-radius: 0;*/
  box-shadow: 0 7px 12px 0 var(--primary02);
}
/* DataTableStyles.css */

/* Override default behavior to show sort icon always */
.react-data-table-component .rdt_Table th .rdt-cell {
  position: relative;
  padding-right: 20px; /* Adjust as needed */
}

.react-data-table-component .rdt_Table th .rdt-cell::after {
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust as needed */
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px; /* Adjust as needed */
  content: "\f0dc"; /* Unicode for fa-sort icon */
  pointer-events: none;
}

/* Additional styling for ascending and descending icons */
.react-data-table-component .rdt_Table th .sort-icon-up::after {
  content: "\f0de"; /* Unicode for fa-sort-up icon */
}

.react-data-table-component .rdt_Table th .sort-icon-down::after {
  content: "\f0dd"; /* Unicode for fa-sort-down icon */
}

.card-with-button .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-card:before {
  background-image: url(../public/assets/images/svgs/circle.svg);
}

.commission-status-badge.ms-2 {
  position: absolute;
  right: 5%;
  font-size: 13px;
}
.commission-amount {
  border: 1px solid;
  margin-left: 10px;
  border-radius: 4px;
}

.image-container {
  width: 100%;
  height: 200px; /* Set the desired height */
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.graphics-download-btn {
  visibility: hidden;
  position: absolute;
  right: 20px;
  top: 20px;
}
.graphic-card:hover .graphics-download-btn {
  visibility: visible;
  transition: 0.2s all;
}

.graphic-card-title {
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden;
  text-overflow: ellipsis; /* Truncate text with an ellipsis */
}
.event-caption {
  position: absolute;
  bottom: -15px;
  color: #fff;
  padding: 9px 15px;
  background: linear-gradient(
    112.1deg,
    rgba(32, 38, 57, 0.4) 11.4%,
    rgb(63, 76, 119) 70.2%
  );
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.event-icon {
  font-size: 25px !important;
  color: #000;
}

.event-logo {
  width: 100px;
  border-radius: 50%;
  display: table;
  height: 100px;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}
.cursor-pointer {
  cursor: pointer;
}

.vs_circle {
  padding: 6px 8px;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-logo {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(48, 48, 48, 0.15), 0 0 0 15px #f3f5f9;
}

.rounded-bg {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(48, 48, 48, 0.15), 0 0 0 15px #f3f5f9;
}

.team-logo img {
  width: 40px;
}

.team-name {
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
}

.match-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-date {
  font-size: 14px;
  color: #8a8f98;
}

.match-date strong {
  color: #1c2a38;
}
.register-date {
  border-left: 1px solid #ccc;
  height: 120px;
  padding: 3% 0 0;
}

.passes-ribbon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ff6347; /* Or any other color you prefer */
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  /* transform: rotate(45deg); */
  z-index: 1;
}

.passes-ribbon span {
  display: block;
  /* transform: rotate(-45deg); */
}
.barcode {
  margin-bottom: -7px;
}

.barcode-text {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: transparent;
  width: 100%;
  background: #fff;
  height: 30px;
  z-index: 17;
}
.invalid-feedback {
  margin-top: 2px !important;
}
.form-label {
  margin-top: 7px;
}

.right-modal > .modal-dialog,
.left-modal > .modal-dialog {
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  width: 650px;
  height: 100%;
  box-shadow: -10px 0px 15px -1px rgba(0, 0, 0, 0.1);
}
.right-modal .modal-content {
  border-radius: 0 !important;
}
.event-ribbon {
  height: 25px;
  padding: 5px 0 0;
  width: 25px;
  z-index: 10;
  right: 17px;
  bottom: 13px;
  position: absolute;
}
.right-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.passes-btn {
  margin-top: -13px;
}
.event-right-arrow {
  margin-right: -20px;
}
.pass-detail-other {
  text-align: right;
  position: absolute;
  right: 15px;
  font-weight: bold;
}
.topBar {
  position: absolute;
  width: 84%;
  background-color: skyblue;
  padding: 0px 20px;
  right: 0;
  top: 0;
  z-index: 10000;
}
.exit-user {
  float: right;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
  background-color: orange !important;
}

@media only screen and (max-width: 769px) {
  .right-modal > .modal-dialog,
  .left-modal > .modal-dialog {
    width: 100% !important;
  }
  .pass-detail {
    display: block !important;
  }
  .pass-detail-other {
    padding: 10px;
    text-align: right;
    width: 100%;
  }
  .commission-status-badge.ms-2 {
    position: relative;
    right: 3px;
    font-size: 11px;
  }
  .topBar {
    width: 100%;
  }
  .page-link {
    font-size: 13px !important;
    padding: 4px 8px;
  }
  .mobile-mt{
    margin-top: 5%;
  }
  .mobile-bt{
    border-top: 10px solid #F2F3F9;
  }
  hr{
    margin: 0;
    padding: 0;
    border: none;
  }
  .register-date{
    border: none;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 900px) {
  .top-cards.total-sales-card-section i{
    display: none;  
  }
  .commission-status-badge{
    right: 10% !important;
  }
} 